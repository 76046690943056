import React from "react";
import { useParams } from "react-router-dom";
import articlesData from "../data/articles.json";
import "../styles/BeatPage.css";
import { Link } from "react-router-dom";

const BeatPage = () => {
  const { beatName } = useParams();
  const articles = articlesData
    .filter(
      (article) => article?.beat.toLowerCase() === beatName?.toLowerCase()
    )
    .sort((a, b) => b.id - a.id); // Sort articles by ID in descending order

  // Separate featured and non-featured articles
  const featuredArticles = articles.filter((article) => article.featured);
  const nonFeaturedArticles = articles.filter((article) => !article.featured);

  // Use the first featured article for main display, if available
  const mainFeaturedArticle =
    featuredArticles[0] || nonFeaturedArticles.shift(); // Fallback to first non-featured if no featured is available

  // Small featured articles (next four non-featured)
  const smallFeaturedArticles = nonFeaturedArticles.slice(0, 4);

  // Remaining articles for list
  const listArticles = nonFeaturedArticles.slice(4);

  return (
    <div className="beat-page">
      <h1>{beatName.toUpperCase()}</h1>
      {/* <div className="wrapper"> */}
        <div className="articles-container">
          <div className="featured-article">
            <Link
              to={`/article/${mainFeaturedArticle?.id}`}
              className="article-link"
            >
               <h2 className="featured-article-header">
                {mainFeaturedArticle?.title}
              </h2>
              <img
                src={`${process.env.PUBLIC_URL}/assets/${mainFeaturedArticle?.thumbnail}`}
                alt={mainFeaturedArticle?.title}
              />
             
              <div className="article-meta">{mainFeaturedArticle?.date}</div>
              <p className="article-abstract">
                {mainFeaturedArticle?.abstract}
              </p>
              <span className="toolTag">{mainFeaturedArticle.tool?.toUpperCase()}</span>
            </Link>{" "}
          </div>
          <div className="small-featured-articles">
            {smallFeaturedArticles?.map((article, index) => (
              <div key={article?.id} className="small-article">
                <Link to={`/article/${article?.id}`} className="article-link">
                  {index === 0 && (
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/${article.thumbnail}`}
                      alt={article.title}
                    />
                  )}
                  <h3>{article.title}</h3>
                  <span className="toolTag">{article.tool?.toUpperCase()}</span>
                  <div className="article-meta">{article.date}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      {/* </div> */}

      <div className="article-list">
        {listArticles.map((article) => (
          <div key={article.id} className="article-list-item">
            <Link to={`/article/${article.id}`} className="article-link">
              <h4 className="article-list-item-title">{article.title}</h4>
              <div className="article-list-item-meta">{article.date}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BeatPage;
