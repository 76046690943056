import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ArticleBlock.css';

const ArticleBlock = ({ article, large }) => {
  const articleClass = large ? "article-block-large" : "article-block";
  const imageClass = large ? "thumb-large" : "thumb-side";

  return (
    <Link to={`/article/${article.id}`} className="article-link">
      <div className={articleClass}>
      {large &&  <span className="toolTag">{article.tool?.toUpperCase()}</span>}
        {large && <h2 className="article-title-large">{article.title}</h2>}
        <img className = {imageClass} src={`${process.env.PUBLIC_URL}/assets/${article.thumbnail}`} alt={article.title} />
        {!large && <div className="article-content">
          <span className="beat">{article.beat}</span>
          <h3 className="title">{article.title}</h3>
          <div className="meta-info">
            <span className="date">{article.date.toUpperCase()}</span>
          </div>
          {!large &&  <span className="nFtoolTag">{article.tool?.toUpperCase()}</span>}
        </div>}
      </div>
    </Link>
  );
};

export default ArticleBlock;