import React from "react";
import "../styles/HomePage.css";
import ArticleBlock from "./ArticleBlock";
import articles from "../data/articles.json"; 
const HomePage = () => {
  // Filter out the featured articles
  const featuredArticles = articles.filter((article) => article.featured);
  const sideArticles = articles
    .filter((article) => !article.featured)
    .sort((a, b) => b.id - a.id) // Sort by ID descending
    .slice(0, 7); // Select only the top 4 most recent

  return (
    <div className="home-page">
      <div className="wrapper">
        <h1 className="latest-title">Latest in Evanston</h1>
        <div className="articles-layout">
          {featuredArticles.map((article) => (
            <ArticleBlock key={article.id} article={article} large />
          ))}
        </div>
        <div className = "nF-layout">
          <div className="side-articles">
            {sideArticles.map((article) => (
              <ArticleBlock key={article.id} article={article} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;