import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Header = () => {
  const beats = ["Culture", "Politics", "Sports", "Business", "Education", "Crime"];

  return (
    <header className="header">
      <div className="main-nav">
        <Link to="/" className="logo">
        <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="Logo" />
        </Link>
        <nav className="beat-nav">
          {beats.map(beat => (
            <Link key={beat} to={`/beat/${beat.toLowerCase()}`} className="nav-link">{beat}</Link>
          ))}
        </nav>
        <div className="social-media">
          <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
          <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} size="lg" />
          </a>
          <a href="https://instagram.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
        </div>
      </div>
      <div className="black-line"></div>
    </header>
  );
};

export default Header;
