import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js';
import HomePage from './components/Homepage.js';
import BeatPage from './components/BeatPage.js';
import ArticleDetails from './components/ArticleDetails.js';
import PasswordWall from './components/PasswordWall.js';
import './App.css';

function App() {
  return (
    <Router>
      <PasswordWall>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/beat/:beatName" element={<BeatPage />} />
            <Route path="/article/:articleId" element={<ArticleDetails />} />
          </Routes>
        </div>
      </PasswordWall>
    </Router>
  );
}

export default App;
