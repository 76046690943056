import React, { useState } from "react";
import "../styles/PasswordWall.css";
const PasswordWall = ({ children }) => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === "KnightLab") {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  if (isAuthenticated) {
    return children;
  }

  return (
    <div className="password-wall">
      <form onSubmit={handleSubmit}>
        <label>
          <div className="text">Enter Password:</div>

          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PasswordWall;
