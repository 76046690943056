import React, { useState, useEffect } from 'react';
import '../styles/ArticleDetails.css';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import articles from '../data/articles.json'; // Assuming the path to your JSON file

const ArticleDetails = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const foundArticle = articles.find(a => a?.id === articleId);
    if (foundArticle) {
      fetch(foundArticle.markdown)
        .then(res => res.text())
        .then(md => {
        
          setArticle({...foundArticle, markdown: md});
          setLoading(false);
        })
        .catch(err => {
          console.error('Error loading markdown:', err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [articleId]);

  if (loading) {
    return <div className="article-details"><p>Loading...</p></div>;
  }

  if (!article) {
    return <div className="article-details"><p>Article not found.</p></div>;
  }

  return (
    <div className="article-details">
      <>
        <h1>{article?.title}</h1>
        <p>{article?.date}</p>
        <img src={`${process.env.PUBLIC_URL}/assets/${article?.thumbnail}`}  alt={article.title} />
        <Markdown>{article?.markdown}</Markdown>
      </>
    </div>
  );
};

export default ArticleDetails;
